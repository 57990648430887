import React from 'react'
import '../ourProducts/ourproducts.css'

import { useNavigate } from 'react-router-dom'
import { DATA } from '../../data'
const OurProducts = () => {
  const navigate = useNavigate()

  return (
    <div className="">
      <div className="section-3">
        <div className="section">
          <div className="service-container">
            <h1>Our Products</h1>
            <div className="service-card">
              {
                DATA.map((el, i) => (
                  <div className="service-card-content" key={i}>
                    <img src={el.productImage} alt="" />
                    <div className="service-body-content">
                      <div className="service-body">
                        <h2>{el.productName}</h2>
                        <p>
                          {el.para}
                        </p>
                      </div>
                      <div className="service-footer">
                        <p>
                         Starting Rs.{el.price}
                        </p>
                        <button type='button' onClick={() => navigate(el.route,{
                          state:{
                            newData:el
                          }
                        })}>Shop Now</button>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
            <div className="service-btn-container">

              <button onClick={() => navigate('/allProduct')} className="service-btn">All Products <i className="fa-solid fa-arrow-right"></i></button>

            </div>

          </div>
        </div>
      </div>
      <section className="new-banner-section">
        <h1>Following 3A Recipe</h1>
        <div className="new-banner">
          <div className="">
            <p>1. Accept</p>
          </div>
          <div className="">
            <p>2. Admire</p>
          </div>
          <div className="">
            <p>3. Adapt</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OurProducts