import React from 'react'
import Layout from '../../layout/Layout'
import { Link } from 'react-router-dom'
import blogimg from '../../assets/vcb.JPG'
const Blog10 = () => {
  const blogdescData = ['1', '2', '3', '4', '5']
  const imgData = ['1', '2', '3', '4', '5', '6']
  return (
    <Layout>
      <div className="hero-container">
        <h1 className="hero-heading">Blog</h1>
      </div>
      <div className="shop-category-section">
        <section className='section'>
          <ul className="breadcrumb">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li>BlogDescription</li>
          </ul>
          <div className="blog-desc">
            <div className="blog-desc-container">
              <div className="blog-desc-left">
               
{/* ******************** */}
<div className="">
                  <p className='head-blog'>Creating the Perfect Skincare Routine: A Guide to Achieving Glowing, Healthy Skin</p>
                  <p className='new-b-para'>
                  Having a consistent skincare routine is essential for maintaining healthy, glowing skin. However, with so many different products and steps to choose from, it can be overwhelming to know where to start. Here is a guide to creating the perfect skincare routine for achieving radiant, healthy skin.
                  </p>
                  <ol>
                    <li>
                    Cleanse: The first step in any skincare routine is cleansing the skin. This involves using a gentle cleanser to remove dirt, oil, and makeup from the skin. It's important to choose a cleanser that is formulated for your skin type and that is gentle enough to use on a daily basis.
                    </li>
                    <li>
                   Tone: The second step in a skincare routine is toning. This step helps to restore the skin's natural pH balance and remove any remaining impurities. Toners are usually applied with a cotton pad and can also provide hydration and nourishment to the skin.
                    </li>
                    <li>
                    Exfoliate: The third step in a skincare routine is exfoliating. This step helps to remove dead skin cells and unclog pores, revealing brighter, smoother skin. There are two types of exfoliation: physical exfoliation and chemical exfoliation.
                    </li>
                    <li>
                    Treat: The fourth step in a skincare routine is treating specific skin concerns. This step can include using products that target specific issues such as acne, wrinkles or hyperpigmentation.
                    </li>

                    <li>
                    Moisturize: The fifth step in a skincare routine is moisturizing. This step helps to keep the skin hydrated, plump and prevent it from drying out. Moisturizers come in various forms such as creams, lotions, and oils, and can be tailored to your specific skin type.
                    </li>
                    <li>
                    Sun protection: The final step in a skincare routine is sun protection. This step is crucial for protecting the skin from the sun's harmful UV rays, which can cause damage to the skin and lead to premature aging. Sunscreen should be applied daily, and it's important to choose a sunscreen that is formulated for your skin type and has an SPF of at least 30.
                    </li>
                  </ol>
                  <p className='new-b-para'>
                  It's important to note that these are general guidelines and some steps may be tailored depending on your skin type,
                      </p>
                </div>

{/* ******************* */}


              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Blog10