import React from 'react'
import '../components/pageNot.css'
const PageNotFound = () => {
  return (
  <div className="page-not">
    <section>
     <div className="page-not-container">
     <h1>404 Page Not Found</h1>
     </div>
    </section>
  </div>
  )
}

export default PageNotFound