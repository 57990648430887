import one from './assets/vcb.JPG'
import two from './assets/bcb.JPG'
import three from './assets/hyc.JPG'

export const BodyProductData = [
 
  {
    id: 2,
    productName: "Vanilla body lotion",
    para:"This creamy indulgent body lotion nourishes and moisturizes your skin to make it soft and smooth. The vanilla and cinnamon instantly refresh your body and your mind",
  
    price: 1099.0,
    productImage:two,
    route:"/product/listtwo",
  },
 
 
];
